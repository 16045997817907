@import url('https://fonts.googleapis.com/css2?family=Saira:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

body {
    background-color: #F5F5F5;
    margin:0;
    -ms-overflow-style: none;
    scrollbar-width: none;
}



.body::-webkit-scrollbar {
    display: none;
  }

.swal2-popup {
    font-family: inherit;
  }


            